const binding = { modules: {}, dataActions: {} };

    (binding.modules['dobbies-back-top-footer'] = {
        c: () => require('partner/modules/dobbies-back-top-footer/dobbies-back-top-footer.tsx'),
        $type: 'contentModule',
        da: [],
        
        iNM: false,
        ns: '__local__',
        n: 'dobbies-back-top-footer',
        p: '__local__',
        
        pdp: '',
        
        
        md: 'src/modules/dobbies-back-top-footer'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };